import React, { Component } from 'react';
import './app.scss';
import DLTHeader from './components/DLTHeader';

import { Routes, Route, useNavigate } from 'react-router-dom';
import { Content, Theme } from '@carbon/react';
import 'core-js/modules/es.array.includes';
import 'core-js/modules/es.array.fill';
import 'core-js/modules/es.string.includes';
import 'core-js/modules/es.string.trim';
import 'core-js/modules/es.object.values';
import LandingPage from './content/LandingPage';
import RepoPage from './content/RepoPage';
import LoginPage from './content/LoginPage';

import { GoogleOAuthProvider } from '@react-oauth/google';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: null,
    };
  }

  handleLogout = () => {
    // Clear the token and user information from state
    this.setState({ token: null, user: null, email: null });
    // Redirect to the landing page or perform other logout tasks
  };

  setToken = (newToken, newUser, newEmail) => {
    console.log('setting FF token to ' + newToken);
    this.setState({ token: newToken, user: newUser, email: newEmail });
    this.props.navigate('/');
  };

  render() {
    const { token, user, email } = this.state;
    return (
      <>
        <GoogleOAuthProvider clientId="929826295274-ntied11tv7jp00uggnm9ju25rt14hc1l.apps.googleusercontent.com">
          <Theme theme="g100">
            <DLTHeader
              user={user}
              email={email}
              isLoggedIn={!!token}
              onLogout={this.handleLogout}
            />
          </Theme>
          <Content>
            <Routes>
              <Route
                exact
                path="/"
                element={<LandingPage token={this.state.token} user={this.state.user}/>}
              />
              {this.state.token ? (
                <Route path="/repos" element={<RepoPage />} />
              ) : null}
              <Route
                path="/login"
                element={<LoginPage setToken={this.setToken} />}
              />
            </Routes>
          </Content>
        </GoogleOAuthProvider>
      </>
    );
  }
}

// Create a functional component wrapper
const AppWrapper = props => {
  const navigate = useNavigate();

  return <App {...props} navigate={navigate} />;
};

export default AppWrapper;
