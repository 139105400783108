import React, { useState, useEffect, useRef } from 'react';
import {
  DataTable,
  Button,
  Modal,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableHeader,
  TableBody,
  TableCell,
  TableToolbar,
  TableToolbarContent,
  TableToolbarSearch,
  Loading,
  Tooltip,
} from '@carbon/react';

import {
  DocumentDownload,
  DocumentView,
  Upload,
  TrashCan,
  Renew,
  Information,
} from '@carbon/react/icons';

import { v4 as uuidv4 } from 'uuid';

const UserFiles = ({ token, isActive, user }) => {
    const [userFileList, setUserFileList] = useState([]);
    const [selectedFile, setSelectedFile] = useState(null);
    const [isModalOpen, setModalOpen] = useState(false);
    const [fileContent, setFileContent] = useState(null);
    const [isLoading, setLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState(""); 
    const [isMobile, setIsMobile] = useState(false); // <-- New state to detect mobile devices
    const fileInputRef = useRef(null);

    // Detect screen size to adjust for mobile view
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768); // Change to true if the width is less than 768px (mobile devices)
        };

        window.addEventListener('resize', handleResize);
        handleResize(); // Set initial value

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    // Fetch file list on component mount or when token changes
    useEffect(() => {
        if (token) {
            fetchUserFileList();
        }
    }, [token, isActive]);

    const fetchUserFileList = async () => {
        const url = `mlapi/premium/task/data/list_detail/files`;
        try {
            const response = await fetch(url, {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const data = await response.json();
            setUserFileList(data.reverse());
        } catch (error) {
            console.error('Error fetching user files:', error);
        }
    };

    const handleDownload = async (fileName) => {
        const url = `mlapi/premium/task/data/get_binary/files/${fileName}`;
        try {
            const response = await fetch(url, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            if (!response.ok) throw new Error('Network response was not ok');
            const blob = await response.blob();
            const downloadUrl = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = downloadUrl;
            a.download = fileName;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(downloadUrl);
            a.remove();
        } catch (error) {
            console.error('Error downloading the file', error);
        }
    };

    const handleFileUpload = async (event) => {
        const file = event.target.files[0];
        if (!file) return;
        
        const formData = new FormData();
        formData.append('file', file);

        const url = `mlapi/premium/task/data/upload/files/${file.name}`;
        try {
            setLoading(true);
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                body: formData,
            });
            if (response.ok) {
                await fetchUserFileList(); // Refresh file list after upload
            } else {
                console.error('File upload failed.');
            }
        } catch (error) {
            console.error('Error uploading the file:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleViewFile = async (fileName) => {
        const url = `mlapi/premium/task/data/get_binary/files/${fileName}`;
        try {
            const response = await fetch(url, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            const content = await response.text();
            setFileContent(content);
            setSelectedFile(fileName);
            setModalOpen(true);
        } catch (error) {
            console.error('Error fetching file content:', error);
        }
    };

    const handleDelete = async (fileName) => {
        const confirmDelete = window.confirm(`Are you sure you want to delete ${fileName}?`);
        if (!confirmDelete) return;

        const url = `mlapi/premium/task/data/delete/files/${fileName}`;
        try {
            const response = await fetch(url, {
                method: 'DELETE',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (!response.ok) {
                throw new Error(`Failed to delete the file. Status: ${response.status}`);
            }

            // Refresh the file list after successful deletion
            await fetchUserFileList();
            alert(`${fileName} has been deleted successfully.`);
        } catch (error) {
            console.error('Error deleting the file:', error);
        }
    };

    // Filter the rows based on the search term
    const filteredRows = userFileList.filter((file) =>
        file.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const rows = filteredRows.map((file) => ({
        id: uuidv4(),
        file_name: file.name,
        created_at: file.created_at === "Unknown" ? "Unknown" : new Date(file.created_at).toLocaleDateString(),
        size: (file.size / 1024).toFixed(2),
        expires_at: file.expires_at === "No expiration" ? "No Expiration" : new Date(file.expires_at).toLocaleDateString(),
    }));

    const triggerFileUpload = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleRefresh = async () => {
        await fetchUserFileList();
        alert('File list refreshed');
    };

    return (
        <>
            Upload files for AI to analyze or tell it to save information in a file you can view or download from here.
            <Tooltip
        label="When using AI, check Use Search Tools to allow it to save and load data from your files.  For example: Get today's news about XYZ and save it to a file with today's date-XYZ"
        align="bottom-right">
        <button className="sb-tooltip-trigger" type="button">
          <Information />
        </button>
      </Tooltip>
            {isLoading && <Loading description="Uploading file..." withOverlay />}
            <TableContainer>
                <TableToolbar>
                    <TableToolbarContent>
                        <TableToolbarSearch
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                        <input
                            type="file"
                            ref={fileInputRef}
                            style={{ display: 'none' }}
                            onChange={handleFileUpload}
                        />
                        <Button
                            renderIcon={Upload}
                            iconDescription="Upload"
                            onClick={triggerFileUpload}
                        >
                            Upload File
                        </Button>
                        <Button
                            renderIcon={Renew}
                            iconDescription="Refresh"
                            onClick={handleRefresh}
                        >
                            Refresh List
                        </Button>
                    </TableToolbarContent>
                </TableToolbar>
                <DataTable
                    rows={rows}
                    headers={[
                        { key: 'file_name', header: 'File Name' },
                        !isMobile && { key: 'created_at', header: 'Created At' }, // Render this column only if not mobile
                        !isMobile && { key: 'expires_at', header: 'Expires At' }, // Render this column only if not mobile
                        { key: 'actions', header: 'Actions' },
                    ].filter(Boolean)} // Filter out null values (conditionally removed columns)
                    render={({ rows, headers, getHeaderProps, getRowProps }) => (
                        <Table>
                            <TableHead>
                                <TableRow>
                                    {headers.map((header) => (
                                        <TableHeader {...getHeaderProps({ header })}>
                                            {header.header}
                                        </TableHeader>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.map((row) => (
                                    <TableRow {...getRowProps({ row })}>
                                        {row.cells.map((cell) => (
                                            <TableCell key={cell.id}>
                                                {cell.info.header === 'actions' ? (
                                                    <>
                                                        <Button
                                                            renderIcon={DocumentDownload}
                                                            hasIconOnly
                                                            tooltipAlignment="center"
                                                            iconDescription="Download"
                                                            onClick={() => handleDownload(row.cells[0].value)}
                                                        />
                                                        <Button
                                                            renderIcon={DocumentView}
                                                            hasIconOnly
                                                            tooltipAlignment="center"
                                                            iconDescription="View"
                                                            onClick={() => handleViewFile(row.cells[0].value)}
                                                        />
                                                        <Button
                                                            renderIcon={TrashCan}
                                                            hasIconOnly
                                                            tooltipAlignment="center"
                                                            iconDescription="Delete"
                                                            onClick={() => handleDelete(row.cells[0].value)}
                                                        />
                                                    </>
                                                ) : (
                                                    cell.value
                                                )}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    )}
                />
            </TableContainer>

            <Modal
                open={isModalOpen}
                modalHeading={`Viewing ${selectedFile}`}
                onRequestClose={() => setModalOpen(false)}
                passiveModal
            >
                <pre>{fileContent}</pre>
            </Modal>
        </>
    );
};

export default UserFiles;
